<template>
    <div id='report'>
        <div v-if="!loadding" class='report' id="pdfDom">
             <!-- 用户信息 -->
            <healingUserInfo :userData="userData" :headImg="headImg"></healingUserInfo>
            <!-- 功能医学检测  -->
            <div class="funMedicine">
                <div class="repHead">
                    <division></division>
                    <div class="hedTitle">
                        <div class="title">了解健康预防疾病从功能医学开始</div>
                        <div class="subtitle">HEALTH STARTS WITH FUNCTIONAL MEDICINE</div>
                    </div>
                </div>
                <div class="content">
                    <div class="text">功能医学就是应用国际上最先进的检查技术，定量检查反应器官功能的各种分子，从而评估器官功能然后再根据检测结果进行维护或纠正的一门医学。<br />它是一种完整性并具有科学基础的医学，除了治疗疾病外，它更提倡健康的维护，通过收集受检者的血液、尿液、唾液、粪便和头发等标本来了解和系统分析我们身体各系统功能以及下降的原因，再依其结果设计一套『量身订做』式的健康管理建议、生活方式指导，帮助您预防疾病，改善亚健康症状及慢性疾病的辅助治疗，享受更优质的生活。
                    </div>
                    <div class="framework">
                        <div class="disease">
                            <div class="distitle">疾病</div>
                            <div>糖尿病</div>
                            <div>心脏病</div>
                            <div>肥胖</div>
                            <div>关节炎</div>
                            <div>癌症</div>
                        </div>
                        <div class="disease reason">
                            <div class="distitle">潜在原因</div>
                            <div>毒性元素暴露</div>
                            <div>免疫功能失衡</div>
                            <div>毒素过多</div>
                            <div>肠道功能下降</div>
                            <div>荷尔蒙失衡</div>
                        </div>

                        <div class="btn1">找"疾病"</div>
                        <div class="btn2">临床医学</div>
                        <div class="btn3">找"健康"</div>
                        <div class="btn4">功能医学</div>
                    </div>
                </div>
            </div>
            <!-- 全身器官功能与储备能力分析 -->
            <div class="funMedicine">
                <div class="repHead">
                    <division></division>
                    <div class="hedTitle">
                        <div class="title">全身器官功能与储备能力分析</div>
                        <div class="subtitleSty">
                            <div>解码器官功能</div>
                            <div>预见健康风险</div>
                            <div>建立健康防护线</div>
                        </div>
                    </div>
                </div>

                <div class="content">
                    <div class="info">
                        <div class="analysis"></div>
                        <div class="in_text">随着年龄的增长，身体各器官系统在结构与功能上都存在进行性改变，组织细胞的活性和修复能力逐渐减退，器官功能下降或亢进，久而久之，疾病就会“找上门”，身体就会生病出现各种不适症状。健康风险评估早期发现器官功能变化，把握身体疾病与健康的密码。
                        <br /><br />
                        功能医学中，评估器官功能而非仅是器官的“病理”，健康风险评估及疾病早筛检测，在“无临床症状”时检测身体的器官功能改变，健康风险评估早期发现器官功能变化，把握身体疾病与健康的密码，勾画“健康隐患”防护线，可作为传统临床医学检测的补充，达到预防医学的目的，采取个性化、针对性的干预手段，预防疾病的发生。
                        </div>
                    </div>

                    <div class="analIcon">
                        <div class="anlitem">
                            <img class="img" :src="`${this.$imgDomain}/www/HealthingCore/organs.png`" alt="">
                        </div>
                        <div class="anlitem">
                            <img class="img" :src="`${this.$imgDomain}/www/HealthingCore/report11.png`" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <!-- 全身器官功能与储备能力相关提示 -->
            <div class="funMedicineTips">
                <div class="repHead">
                    <division></division>
                    <div class="hedTitle">
                        <div class="title">{{abilityTips.title}}</div>
                        <div class="subtitle">{{abilityTips.translate}}</div>
                    </div>
                </div>
                <div class="tipsInfo">
                    <div class="tipsItem" v-for="(item, index) in abilityTips.contnet" :key="index">
                        <div class="tipsIcon">{{index+1}}</div>
                        <div class="tipTitle">{{item.title}}</div>
                        <div class="textBody">
                            <div v-for="(text, i) in item.text" :key="i" class="text">{{text}}</div>
                        </div>
                    </div>
                    <!-- 图例 -->
                    <div class="legend">
                        <div class="tipsIcon">5</div>
                        <div class="tipTitle">关于此报告健康红绿灯理解分析</div>
                        <div class="info">
                            <div class="tipColor"></div>
                            <div>风险提示，可能有症状或正在发展中;</div>
                        </div>
                        <div class="info">
                            <div class="tipColor7"></div>
                            <div>风险提示，可能无症状或相对稳定中;</div>
                        </div>
                        <div class="info">
                            <div class="tipColor3"></div>
                            <div>提示关注，可能是有症状的亚健康或已经存在的健康问题;</div>
                        </div>
                        <div class="info">
                            <div class="tipColor4"></div>
                            <div>提示关注，可能是无或低症状的亚健康或已经存在的健康问题;</div>
                        </div>
                        <div class="info">
                            <div class="tipColor5"></div>
                            <div>当下无风险，请保持健康生活方式;</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 全身器官功能平衡分析 -->
            <generalAnalysis :analysisData="analysisData1"></generalAnalysis>
            <!-- 全身器官功能平衡分析 -->
            <healingIfeelDetection :detectionData="detectionData2"></healingIfeelDetection>
            <!-- 身体矩阵 -->
            <!-- <analysisDetection :detectionData="detectionData3"></analysisDetection> -->
            <!-- 影响身体健康风险的核心因素 -->
            <healingIfeelDetection :detectionData="detectionData4"></healingIfeelDetection>
            <!-- 脂肪代谢 -->
            <!-- <analysisDetection :detectionData="detectionData5"></analysisDetection> -->

            <!-- 全身器官功能 & 储备能力分析 -->
            <div v-for="(item, i) in reportData" :key="i">
                <healingIfeelDetection :detectionData="item"></healingIfeelDetection>
            </div>
        </div>
        <!-- loading -->
        <van-loading v-else size="24px" vertical>加载中...</van-loading>
    </div>
</template>

<script>
import division from './components/division'
import healingUserInfo from './components/healingUserInfo'
import healingIfeelDetection from './components/healingIfeelDetection.vue'
import generalAnalysis from './components/generalAnalysis.vue'
import { getHealingIfeelReport } from '@/api/index'
import { sortArr, unique } from '@/util/validate'

export default {
  components: {
    division,
    healingUserInfo,
    generalAnalysis,
    healingIfeelDetection
  },
  data () {
    return {
      userData: {},
      headImg: `${this.$imgDomain}/www/HealthingCore/head3.png`,
      reportData: [],
      loadding: false,
      analysisData1: {
        title: '全身核心器官功能对比分析',
        translate: 'CORE ORGAN FUNCTION ANALYSIS',
        img: 'https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/report10.png',
        color: '#fff',
        text: '当人体某一器官受损（病变、外伤等）时，该器官不能完成其本体的生理功能，从而连带其他器官衰竭，形成连锁反应，这种连锁反应，叫人体短板效应木桶理论告诉我们，生命的长短和质量，并不取决于我们某个器官或某个部位特别健康，特别发达，一块板再长，也不能让我们的生命之桶盛装更多的水。若想生命长久而鲜活，需要我们所有的器官，所有的零部件，都健康。',
        leftBag: 'https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/bag1.png',
        downBag: 'https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/bag4.png',
        imgSize: true
      },
      detectionData2: {
        title: '全身核心器官功能对比分析',
        translate: 'CORE ORGAN FUNCTION ANALYSIS',
        img: 'https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/report4.png',
        color: '#fff',
        histogram: {},
        tips: false // 图例开关
      },
      detectionData3: {
        title: '七大生理失衡矩阵图',
        translate: 'CORE ORGAN FUNCTION ANALYSIS',
        img: 'https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/report4.png',
        color: '#fff',
        matrixArr: [
          {
            title: '消化吸收能力',
            top: '-120px',
            left: '-140px',
            child: [],
            summary: '肠道“坏”菌群过多'
          },
          {
            title: '结构完整性',
            top: '80px',
            left: '-140px',
            child: []
          },
          {
            title: '传递系统',
            top: '250px',
            left: '-140px',
            child: [],
            summary: '肾上腺分泌压力激素与神经递质异常'
          },

          {
            title: '传输系统',
            top: '300px',
            left: '32%',
            child: []
          },
          {
            title: '防御与修护',
            top: '-120px',
            right: '-140px',
            child: []
          },
          {
            title: '能量生成',
            top: '80px',
            right: '-140px',
            child: [],
            summary: '抗氧化能力下降产生过多自由基'
          },
          {
            title: '代谢转换与消除',
            top: '250px',
            right: '-140px',
            child: [],
            summary: '肝脏解毒路径被抑制',
            summary2: '体内重金属超标',
            summary3: '接触过多塑化剂，体内蓄积环境激素',
            summary4: '雌激素占优势'
          }
        ],
        matrix: true
      },
      detectionData4: {
        title: '当前身体核心健康风险分析',
        translate: 'CURRENT CORE PROBLEMS',
        img: 'https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/report4.png',
        color: '#fff',
        coreData: []
      },
      detectionData5: {
        title: '脂肪代谢分析',
        translate: 'ANALYSIS OF FAT FACTORS',
        img: 'https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/report4.png',
        color: '#fff',
        factorAnalysis: true,
        analTips: true,
        analysisArr: [
          {
            text: ['甲状腺'],
            left: '-130px',
            top: '-20px'
          },
          {
            text: ['肝脏'],
            left: '-130px',
            top: '80px'
          },
          {
            text: ['荷尔蒙'],
            left: '-130px',
            top: '180px'
          },
          {
            text: ['压力'],
            left: '-130px',
            top: '280px'
          },
          {
            text: ['胰岛素抵抗'],
            left: '-130px',
            top: '380px'
          },

          {
            text: ['脑血管', '脂肪堆积'],
            right: '-130px',
            top: '-20px'
          },
          {
            text: ['心血管', '脂肪堆积'],
            right: '-130px',
            top: '80px'
          },
          {
            text: ['腹腔', '脂肪堆积'],
            right: '-130px',
            top: '180px'
          },
          {
            text: ['肝脏', '脂肪堆积'],
            right: '-130px',
            top: '280px'
          },
          {
            text: ['肠道', '脂肪堆积'],
            right: '-130px',
            top: '380px'
          }
        ]
      },
      abilityTips: {
        title: '全身器官功能与储备能力分析相关提示',
        translate: 'ORGAN FUNCTIONAL ANALYSIS',
        contnet: [
          {
            title: '关于此分析报告提示',
            text: ['全身器功能与储备能力分析报告只做健康评估参考，不做疾病诊断，更不能代替医学检测。']
          },
          {
            title: '关于器官功能状态提示',
            text: ['器官功能状态除了与疾病相关外，更与日常生活方式相关。', '身体有疾病时器官功能也可能是稳定，器官功能不稳定时候也可能目前没有相关疾病风险。']
          },
          {
            title: '关于健康风险分析提示',
            text: ['每个器官都有相关风险分析范围：', '①、如你现有健康问题不在此范围内，此分析报告无法体现；', '②、如身体已有的疾病/健康问题目前控制得很好，该疾病/健康问题风险分析是很可能在绿色或黄色范围，就像吃药物血压控制很平稳的时候去测血压，血压计一定是在正常值范围。']
          },
          {
            title: '关于此分析报告价值提示',
            text: ['此分析报告价值除了是日常生活比较便捷的健康评估参考之外，更多是以功能医学思维作为疾病发生相关因素矩阵分析，让你了解自己的疾病/不舒服的原因在哪里。']
          }
        ]
      },
      organOrder: ['大脑', '甲状腺', '咽喉', '肺', '心脏', '心血管', '肝', '胆', '肾上腺', '肾', '胃', '胰腺', '肠道', '乳腺', '前列腺', '子宫', '卵巢', '免疫系统', '骨关节']
    }
  },
  props: {

  },
  created () {

  },
  mounted () {
    this.getReportPageData()
  },
  methods: {
    getReportPageData () {
      this.loadding = true
      const formData = new FormData()
      const id = this.$query('id')
      // formData.append('action', 'GetTotalReportByAutoId');
      formData.append('UserDetailId', id)
      getHealingIfeelReport(formData).then(res => {
        // const { data } = res
        if (res && res.data.ifeelInfoList.length > 0) {
          this.userData = res.data.userExpands
          const data = res.data.ifeelInfoList.filter(item => {
            if (item.belongOrgan.indexOf('运动障碍') !== -1 || item.belongOrgan.indexOf('赘生物') !== -1 || item.belongOrgan.indexOf('肾下垂') !== -1) {
              if (item.ex1 <= 0.5 && item.ex2 > 4) {
                return item
              }
            } else if (item.belongOrgan.indexOf('内分泌功能失衡') !== -1) {
              if (item.ex1 <= 0.6 && item.ex2 > 3) {
                return item
              }
            } else {
              return item
            }
          })

          // 数据组合
          const reportData = sortArr(data, 'belongOrgan')
          // 柱状图 数据处理
          this.handleHistogram(reportData)

          // 核心因素
          const coreData = data.filter(item => {
            if (item.belongOrgan === '躯干') {
              return item
            }
          })
          const sclerod = data.filter(item => {
            if (item.Category === '增生或囊肿体质' && item.ex1 < 1) {
              return item
            }
          })
          if (sclerod.length > 3) {
            const maxMathData = Math.max.apply(Math, sclerod.map(key => key.ex2))
            const sclerodItme = sclerod.find(key => maxMathData === key.ex2)
            coreData.push(sclerodItme)
          }
          this.traverseArr(coreData)

          // 身体矩阵
          // this.handleMatrix(result.matrixList);

          // 脂肪因素分析 数据处理
          // const factorData = sortArr(data, 'ShowFatFactor');
          // this.adiposeFactor(factorData);

          // 功能评估 数据处理
          this.functionalEvaluation(reportData)
        } else {
          this.$toast('暂无报告数据')
        }
        this.loadding = false
      })
    },
    // 柱状图 数据处理
    handleHistogram (data) {
      const that = this
      const MinArr = []
      const xAxisData = []
      const seriesDate = []
      data.forEach(el => {
        const minMathData = Math.min.apply(Math, el.map(item => { return item.ex1 }))
        MinArr.push(el.find(item => minMathData === item.ex1))
      })

      // 数据指定排序
      MinArr.sort(function (a, b) {
        return that.organOrder.indexOf(a.belongOrgan) - that.organOrder.indexOf(b.belongOrgan)
      })

      MinArr.map(item => {
        if (item.belongOrgan !== '高血压' && item.belongOrgan !== '躯干' && item.belongOrgan !== '咽喉' && item.belongOrgan !== '皮肤' && item.belongOrgan !== '骨关节' && item.belongOrgan !== '眼睛' && item.belongOrgan !== '交感神经' && item.belongOrgan !== '脂肪堆积') {
          if (this.userData.ex3 === '男') {
            if (item.belongOrgan !== '子宫' && item.belongOrgan !== '卵巢') {
              if (item.ex1 > 0.8) {
                seriesDate.push(0.9)
              } else {
                seriesDate.push(item.ex1)
              }
              xAxisData.push(item.belongOrgan)
            }
          } else {
            if (item.ex1 > 0.8) {
              seriesDate.push(0.9)
            } else {
              seriesDate.push(item.ex1)
            }
            xAxisData.push(item.belongOrgan)
          }
        }
      })

      this.detectionData2.histogram = {
        xAxisData: xAxisData,
        seriesDate: seriesDate
      }
      this.$forceUpdate()
    },
    traverseArr (data) {
      data = unique(data, 'category')
      let MinArr = []
      data.forEach(item => {
        item.proportion = item.ex2 / 10 * 100

        if (item.ex1 <= 0.425) {
          if (item.ex2 <= 4) {
            // 浅红色
            item.bgaColor = '#B21E23'
          } else {
            // 深红色
            item.bgaColor = '#86272A'
          }
          item.text = '发展中'
        } else if (item.ex1 > 0.425 && item.ex1 <= 0.7) {
          if (item.ex2 <= 4) {
            // 浅黄色
            item.bgaColor = '#E8EA70'
          } else {
            // 深黄色
            item.bgaColor = '#D89F15'
          }
          item.text = '发展中'
        } else {
          // 浅黄色
          item.bgaColor = '#E8EA70'
          item.text = '相对稳定'
        }

        // 内分泌功能失衡 单独设置
        if (item.belongOrgan.indexOf('内分泌功能失衡') !== -1) {
          // 深黄色
          item.bgaColor = '#D89F15'
          item.text = '不稳定'
        }

        MinArr.push(item)
      })
      // 按照病理值从小到大排序
      MinArr.sort((a, b) => {
        return a.ex1 - b.ex1
      })

      // 取前4条数据
      MinArr = MinArr.slice(0, 4)
      this.detectionData4.coreData = MinArr
    },
    // 功能评估 数据处理
    functionalEvaluation (array) {
      const data = JSON.parse(JSON.stringify(array))
      const that = this
      const reportData = []
      data.forEach(el => {
        const functionData = {
          child: []
        }

        // 风险分析
        const sortData = sortArr(el, 'category') // 筛选组合
        sortData.forEach(item => {
          const minMathData = JSON.parse(JSON.stringify(Math.min.apply(Math, item.map(key => key.ex1))))
          const screenItme = item.find(key => minMathData === key.ex1)
          screenItme.proportion = screenItme.ex2 / 10 * 100

          if (screenItme.ex2 <= 4) {
            screenItme.text = '相对稳定'
          } else {
            screenItme.text = '发展中'
          }
          // 柱状长度
          if (screenItme.ex1 <= 0.425) {
            if (screenItme.ex2 <= 4) {
              // 浅红色
              screenItme.bgaColor = '#B21E23'
            } else {
              // 深红色
              screenItme.bgaColor = '#86272A'
            }
          } else if (screenItme.ex1 > 0.425 && screenItme.ex1 <= 0.7) {
            if (screenItme.ex2 <= 4) {
              // 浅黄色
              screenItme.bgaColor = '#E8EA70'
            } else {
              // 深黄色
              screenItme.bgaColor = '#D89F15'
            }
          } else if (screenItme.ex1 > 0.7 && screenItme.ex1 <= 0.9) {
            if (screenItme.ex2 <= 4) {
              // 绿色
              screenItme.bgaColor = '#71A241'
            } else {
              // 浅黄色
              screenItme.bgaColor = '#E8EA70'
            }
          } else {
            // 绿色
            screenItme.bgaColor = '#71A241'
            screenItme.proportion = '10'
            // 风险tips
            if (screenItme.ex2 <= 4) {
              screenItme.text = '稳定'
            } else {
              screenItme.text = '相对稳定'
            }
          }

          // 警告控制
          // if(screenItme.MainId.indexOf('躯干') !== -1){
          //     screenItme.warningShow = true;
          // } else {
          //     screenItme.warningShow = false;
          // }

          functionData.belongOrgan = screenItme.belongOrgan
          functionData.child.push(screenItme)
        })
        // 风险分析 -end

        // 风险分析从小到大排序
        functionData.child = functionData.child.sort(function (a, b) {
          return a.ex1 - b.ex1
        })

        // 功能状态分析 --start
        const minMathData = JSON.parse(JSON.stringify(Math.max.apply(Math, functionData.child.map(item => item.proportion))))
        functionData.function = functionData.child.find(item => minMathData === item.proportion) // 最大的状态值

        // 器官图片选择
        const img = this.organPictures(functionData.belongOrgan)
        functionData.img = img
        functionData.headType = 'organ'
        functionData.color = '#474645'
        // 个别器官不展示
        if (functionData.belongOrgan !== '脂肪堆积' && functionData.belongOrgan !== '躯干') {
          if (this.userData.Ex3 === '男') {
            if (functionData.belongOrgan !== '子宫' && functionData.belongOrgan !== '卵巢') {
              reportData.push(functionData)
            }
          } else {
            reportData.push(functionData)
          }
        }
      })
      // 指定顺序处理
      reportData.sort(function (a, b) {
        return that.organOrder.indexOf(a.belongOrgan) - that.organOrder.indexOf(b.belongOrgan)
      })

      this.reportData = reportData
      this.coreAndFuntion()
    },
    // 核心数据与器官数据逻辑处理
    coreAndFuntion () {
      const MinArr = this.detectionData4.coreData
      if (MinArr.length === 0) {
        return
      }
      const reportData = this.reportData
      const newMinArr = []
      const newReportData = []
      MinArr.forEach(item => {
        reportData.forEach(el => {
          el.child.forEach(val => {
            if (item.Category === val.Category) {
              if (item.ex1 < val.ex1) {
                // 躯干数据病理值更小
                val = item
              }
              if (item.ex1 > val.ex1) {
                // 功能数据病理值更小
                item = val
              }
            }
          })
        })
        newMinArr.push(item)
      })
      reportData.forEach(el => {
        MinArr.forEach(item => {
          el.child.forEach((val, index) => {
            if (item.Category === val.Category) {
              if (item.ex1 < val.ex1) {
                // 躯干数据病理值更小
                el.child[index] = item
              }
              if (item.ex1 > val.ex1) {
                // 功能数据病理值更小
                item = el.child[index]
              }
            }
          })
        })
        newReportData.push(el)
      })

      // 核心数据重新赋值
      this.traverseArr(newMinArr)
      this.reportData = newReportData
    },
    // 身体矩阵数据处理
    handleMatrix (data) {
      if (!data) {
        return
      }
      const matrixArr = this.detectionData3.matrixArr
      data.forEach(item => {
        matrixArr.forEach(el => {
          if (item.ex2.indexOf('%') !== -1) {
            item.Ex3 = item.ex2.split('%')[0]
            item.Category = el.summary
          }
          if (item.ex1 <= 0.425) {
            item.color = '#86272A'
          } else {
            item.color = '#D89F15'
          }
          if (item.Ex3 >= 35) {
            item.color = '#D89F15'
          }

          if (el.title === item.FactorType && (item.ex1 < 0.9 || item.Ex3 < 35)) {
            el.child.push(item)
          }
          el.child = unique(el.child, 'Category')
        })
      })
      matrixArr.forEach(item => {
        item.child = unique(item.child, 'belongOrgan')
        const everyData = item.child.every((value, index, ar) => {
          if (value.TitleName !== '') {
            return true
          } else {
            return false
          }
        })
        if (everyData) {
          item.coreShow = true
        }
      })
    },
    // 脂肪代谢分析 数据处理
    adiposeFactor (data) {
      const analysisData = this.detectionData5.analysisArr
      data.map(item => {
        const minMathData = Math.min.apply(Math, item.map(el => { return el.ex1 }))
        const mindata = item.find(el => minMathData === el.ex1)
        analysisData.forEach(el => {
          el.textMerge = el.text.join('')
        })
        analysisData.find(el => {
          if (mindata.ShowFatFactor === el.textMerge) {
            if (mindata.ex1 <= 0.6) {
              el.color = '#A12924'
            } else if (mindata.ex1 > 0.6 && mindata.ex1 < 0.9) {
              el.color = '#E8E080'
            } else {
              el.color = '#71A241'
            }
            return el
          }
        })
      })
    },
    // 器官图片
    organPictures (name) {
      const orginImgUrl = `${this.$imgDomain}/www/HealthingCore/organ`
      let imgUrl = ''
      switch (name) {
        case '肠道':
          imgUrl = `${orginImgUrl}1`
          break
        case '大脑':
          imgUrl = `${orginImgUrl}2`
          break
        case '胆囊':
          imgUrl = `${orginImgUrl}3`
          break
        case '肺':
          imgUrl = `${orginImgUrl}4`
          break
        case '骨关节':
          imgUrl = `${orginImgUrl}6`
          break
        case '甲状腺':
          imgUrl = `${orginImgUrl}7`
          break
        case '交感神经':
          imgUrl = `${orginImgUrl}8`
          break
        case '卵巢':
          imgUrl = `${orginImgUrl}9`
          break
        case '免疫系统':
          imgUrl = `${orginImgUrl}11`
          break
        case '皮肤':
          imgUrl = `${orginImgUrl}12`
          break
        case '前列腺':
          imgUrl = `${orginImgUrl}13`
          break
        case '乳腺':
          imgUrl = `${orginImgUrl}14`
          break
        case '肾':
          imgUrl = `${orginImgUrl}16`
          break
        case '胃':
          imgUrl = `${orginImgUrl}17`
          break
        case '心血管':
          imgUrl = `${orginImgUrl}18`
          break
        case '心脏':
          imgUrl = `${orginImgUrl}19`
          break
        case '眼睛':
          imgUrl = `${orginImgUrl}20`
          break
        case '咽喉':
          imgUrl = `${orginImgUrl}21`
          break
        case '胰腺':
          imgUrl = `${orginImgUrl}22`
          break
        case '子宫':
          imgUrl = `${orginImgUrl}23`
          break
        case '肾上腺':
          imgUrl = `${orginImgUrl}24`
          break
        case '肝':
          imgUrl = `${orginImgUrl}5`
          break
        case '胆':
          imgUrl = `${orginImgUrl}25`
          break
        default:

          break
      }

      return imgUrl + '.png'
    }
  },
  watch: {

  },
  computed: {

  }
}
</script>
<style lang='scss' scoped>
#report {
    width: 100%;
}
.report {
    width: 592.28px;
    height: 100%;
    padding: 10px;
    color: #474645;
    .funMedicine,
    .funMedicineTips {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #F5F5F5;
        padding: 50px 0 40px 0;
        margin-top: 40px;
        border-radius: 20px;
        min-height: 860px;
        .repHead {
            width: 100%;
            .hedTitle {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 40px 0;
                .title {
                    font-size: 24px;
                    font-weight: 700;
                }
                .size {
                    font-size: 20px;
                }
                .subtitle {
                    font-size: 14px;
                    margin-top: 5px;
                }
                .subtitleSty {
                    margin-top: 10px;
                    width: 80%;
                    display: flex;
                    justify-content: space-around;
                }
            }
        }
        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            .text {
                width: 70%;
                line-height: 20px;
            }
            .framework {
                position: relative;
                width: 60%;
                height: 300px;
                background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/report1.png');
                background-size: cover;
                background-repeat:no-repeat;
                background-position:center;
                margin-top: 50px;
                .disease {
                    text-align: center;
                    color: #fff;
                    line-height: 20px;
                    .distitle {
                        font-size: 18px;
                    }
                }
                .reason{
                    position: absolute;
                    top: 43%;
                    width: 100%;
                    line-height: 25px;
                }

                .btn1,
                .btn2,
                .btn3,
                .btn4 {
                    position: absolute;
                    width: 70px;
                    font-size: 14px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    border-radius: 30px;
                    color: #fff;
                    background-repeat:no-repeat;
                    background-position: center;
                }
                .btn1,
                .btn3 {
                    background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/btn1.png');
                    background-size: 100% 100%;
                }
                .btn2,
                .btn4 {
                    background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/btn2.png');
                    background-size: 100% 100%;

                }
                .btn1 {
                    top: 40px;
                    left: -80px;
                }
                .btn2 {
                    top: 40px;
                    right: -80px;
                }
                .btn3 {
                    top: 200px;
                    left: -80px;
                }
                .btn4 {
                    top: 200px;
                    right: -80px;
                }
            }
            .info {
                display: flex;
                justify-content: space-between;
                margin-bottom: 50px;
                padding: 0 10px;
                .analysis {
                    width: 45%;
                    height: 250px;
                    border-radius: 10px;
                    background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/analysis.png');
                    background-repeat:no-repeat;
                    background-position:center;
                }
                .in_text {
                    width: calc(55% - 20px);
                }
            }
            .analIcon {
                display: flex;
                justify-content: space-around;
                width: 100%;
                .anlitem {
                    width: 150px;
                    height: 150px;
                }
            }
        }
    }
    .funMedicineTips {
        padding: 20px 20px 0;
        .hedTitle {
            margin: 30px 0 !important;
        }
        .tipsInfo {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .tipsItem {
                width: 48%;
                height: 180px;
                border-radius: 15px;
                border: 1px solid#5CC1DB;
                padding: 20px 10px 10px;
                margin-top: 30px;
                position: relative;
                .tipTitle {
                    text-align: center;
                    font-size: 14px;
                    font-weight: 700;
                }
                .textBody {
                    .text {
                        line-height: 17px;
                    }
                }
            }
            .legend {
                width: 100%;
                padding: 10px;
                border: 1px solid#5CC1DB;
                position: relative;
                margin-top: 30px;
                padding-top: 30px;
                border-radius: 15px;
                .tipTitle {
                    text-align: center;
                    font-size: 14px;
                    font-weight: 700;
                }
                .info {
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 10px;
                    .tipColor {
                        min-width: 40px;
                        margin-right: 5px;
                        background: #A12924,
                    }
                    .tipColor2 {
                        min-width: 40px;
                        margin-right: 5px;
                        background: #B21E23;
                    }
                    .tipColor3 {
                        min-width: 40px;
                        margin-right: 5px;
                        background: #BF8341,
                    }
                    .tipColor4 {
                        min-width: 40px;
                        margin-right: 5px;
                        background: #E8E080,
                    }
                    .tipColor5 {
                        min-width: 40px;
                        margin-right: 5px;
                        background: #71A241,
                    }
                    .tipColor6 {
                        min-width: 40px;
                        margin-right: 5px;
                        background: #A12924,
                    }
                    .tipColor7 {
                        min-width: 40px;
                        margin-right: 5px;
                        background: #c35d61,
                    }
                }
            }
            .tipsIcon {
                position: absolute;
                left: 50%;
                top: -20px;
                transform: translateX(-50%);
                width: 40px;
                height: 40px;
                border-radius: 20px;
                background: #5CC1DB;
                color: #fff;
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
            }
        }
    }
    .dowmload {
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }
    .img {
        width: 100%;
        height: 100%;
    }

}
</style>
